// Actions
const LOAD_METADATA = "loadMetaData";
const CHANGE_SIDEBAR_STATE = "changeSidebarState";
const CHANGE_PAGE_LOADING_STATE = "changePageLoadingState";
const TOGGLE_SIDEBAR = "toggleSidebar";
const TOGGLE_MOBILE_AVAILABILITY_MENU = "toggleMobileAvailabilityMenu";
const CHANGE_MOBILE_AVAILABILITY_MENU = "changeMobileAvailabilityMenu";
const CHANGE_DOCUMENT_READY_STATE = "changeDocumentReadyState";

// Mutations
const SET_METADATA = "setMetaData";
const SET_SIDEBAR_STATE = "setSidebarState";
const SET_PAGE_LOADING_STATE = "setPageLoadingState";
const SET_MOBILE_AVAILABILITY_MENU = "setMobileAvailabilityMenu";
const SET_DOCUMENT_READY_STATE = "setDocumentReadyState";

// Namespace
const NAMESPACE = "base"

export const withNamespace = (string: string) => {
    return `${NAMESPACE}/${string}`;
}

export default {
    action: {
        TOGGLE_SIDEBAR,
        CHANGE_SIDEBAR_STATE,
        LOAD_METADATA,
        CHANGE_PAGE_LOADING_STATE,
        TOGGLE_MOBILE_AVAILABILITY_MENU,
        CHANGE_MOBILE_AVAILABILITY_MENU,
        CHANGE_DOCUMENT_READY_STATE
    },
    mutation: {
        SET_SIDEBAR_STATE,
        SET_METADATA,
        SET_PAGE_LOADING_STATE,
        SET_MOBILE_AVAILABILITY_MENU,
        SET_DOCUMENT_READY_STATE
    },
    namespace: NAMESPACE,
    withNamespace
}