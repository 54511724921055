var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: ((_obj = { icon: true }), (_obj["icon-" + _vm.icon] = true), _obj),
    style: { "font-size": _vm.size + "px", color: _vm.color },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }