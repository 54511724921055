<template>
  <div
    id="send-request"
    :class="{ 'molecule-request-offer': true, active: isShown }"
  >
    <div class="popup-screen small-popup-screen">
      <span class="btn-close-popup" @click="closeModal"
        ><AtomIcon icon="xmark" :size="16"
      /></span>
      <div class="content-center">
        <div class="content-center-wrapper">
          <div class="title-subtitle">
            <h3>{{ message }}</h3>
          </div>
          <AtomButton type="button" label="Continue" :on-click="closeModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
import AtomButton from "@/components/atoms/common/AtomButton.vue";
export default {
  name: "MoleculeSendRequest",
  components: { AtomButton, AtomIcon },
  props: {
    message: {
      required: false,
      default: "Your request has been submitted",
      type: String,
    },
  },
  data() {
    return {
      isShown: false,
    };
  },
  methods: {
    toggleShow() {
      this.isShown = !this.isShown;
    },
    closeModal() {
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(modal.withNamespace(modal.action.CLOSE_MODAL));
      this.isShown = false;
      document.body.classList.remove("disable-scroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-request-offer {
  display: none;
  &.active {
    display: block;
  }
}
</style>
