// Actions
const ADD_SPACE = "addSpace";
const REMOVE_SPACE = "removeSpace";
const CHANGE_CART_SPACES_STATE = "changeCartSpacesState";

// Mutations
const SET_SPACES = "setSpaces";
const SET_CART_SPACES_STATE = "setCartSpacesState";

// Actions & Mutations
const CLEAR_STATE = "clearState";

// Namespace
const NAMESPACE = "requestOffer";

// Cache
const CACHE_NAME = "requestOfferCache";

export const withNamespace = (string: string) => {
    return `${NAMESPACE}/${string}`;
}

export default {
    action: {
        ADD_SPACE,
        REMOVE_SPACE,
        CLEAR_STATE,
        CHANGE_CART_SPACES_STATE
    },
    mutation: {
        SET_SPACES,
        CLEAR_STATE,
        SET_CART_SPACES_STATE
    },
    namespace: NAMESPACE,
    cacheName: CACHE_NAME,
    withNamespace
}