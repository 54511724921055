<template>
  <div class="input-atom-wrapper">
    <input
      ref="input"
      v-if="type !== types.TEXTAREA"
      :type="type"
      v-model="inputVal"
      :placeholder="placeholder"
      :name="name"
    />
    <textarea
      ref="textarea"
      v-else
      v-model="inputVal"
      placeholder="Message"
    ></textarea>
    <span class="error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
export const EnumInputTypes = {
  TEXT: "text",
  DATE: "date",
  TEXTAREA: "textarea",
  NUMBER: "number",
  PASSWORD: "password",
  TEL: "tel",
};

export default {
  name: "AtomInput",
  props: {
    value: {
      required: false,
      type: String,
      default: "",
    },
    type: {
      required: false,
      type: String,
      default: EnumInputTypes.TEXT,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Input value...",
    },
    name: {
      required: false,
      type: String,
    },
    error: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      types: EnumInputTypes,
      // err: this.error,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    resetInput() {
      this.inputVal = "";
    },
    focus(opts = {}) {
      if (this.type === this.types.TEXTAREA) {
        this.$refs.textarea.focus(opts);
        return;
      }
      return this.$refs.input.focus(opts);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-atom-wrapper {
  margin: 0;
  flex: 1;
  width: 100%;

  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      padding: 5px 0;
    }
  }

  input,
  textarea {
    background: #f5f6fb;
    border: none;
    border-radius: 5px;
    padding: 0.625rem 0.938rem;
    color: #000;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: $regular;
    width: 100%;
    @media only screen and (max-width: 767px) {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 18px;
    }

    &:focus-visible {
      border: none !important;
      box-shadow: none !important;
      outline-width: 0 !important;
    }

    &::placeholder {
      color: $second-color;
    }
  }

  input {
    height: 2.5rem;
    @media only screen and (max-width: 767px) {
      height: 40px;
    }
  }

  textarea {
    height: 6.25rem;
    min-height: 6.25rem;
    max-height: 6.25rem;
    max-width: 100%;
    min-width: 100%;
    @media only screen and (max-width: 767px) {
      height: 80px;
      min-height: 80px;
      max-height: 80px;
    }
  }
}
</style>
