import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import {ModalState} from "./types";

const state: ModalState = {
    modalType: '',
    showModal: false
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}