<template>
  <div class="atom-check-box" @click="handleClick">
    <input :id="id" ref="checkbox" type="checkbox" :name="name" :value="checked" />
    <label v-html="label"></label>
    <span :class="{'check-box': true, checked}">
      <AtomIcon v-if="checked" icon="square-check" :size="size"/>
      <AtomIcon v-else icon="square" :size="size"/>
    </span>
    <span class="error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";

export default {
  name: "AtomCheck",
  components: {AtomIcon},
  props: {
    id: {
      required: false,
      type: String,
      default: '',
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    name: {
      required: false,
      type: String,
      default: 'checkbox',
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 22,
    },
    error: {
      required: false,
      type: String,
    }
  },
  data() {
    return {
      checked: this.$props.value
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    handleClick() {
      this.checked = !this.checked;
      this.inputVal = this.checked;
    },
    setStatus(status) {
      this.checked = status;
      this.inputVal = this.checked;
    }
  }
}
</script>

<style lang="scss">
.atom-check-box {
  position: relative;
  padding-left: 1.875rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      padding: 5px 0;
    }
  }
  input[type='checkbox'] {
    display: none;
  }
  label {
    font-weight: $regular;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #0A0A0A;
    cursor: pointer;
    position: relative;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
    a {
      color: $main-color;
      font-size: 0.875rem;
      line-height: 1.063rem;
      text-decoration: underline !important;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  .check-box {
    position: absolute;
    left: -0.375rem;
    top: -0.35rem;
    @media only screen and (max-width: 767px) {
      left: -5px;
      top: -5px;
    }
    i {
      font-size: 1.375rem !important;
      color: $main-color !important;
      @media only screen and (max-width: 767px) {
        font-size: 20px !important;
      }
    }
    &.checked {
      i {
        color: $second-color !important;
      }
    }
  }
}
</style>