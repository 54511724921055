import {baseConstants, baseModule} from "./base";
import {projectConstants, projectModule} from "./project";
import {modalConstants, modalModule} from "./modal";
import {requestOfferConstants, requestOfferModule} from "./requestOffer";
import {offerConstants, offerModule} from "./offer";
import {spaceConstants, spaceModule} from "./space";

export default {
    [baseConstants.namespace]: baseModule,
    [projectConstants.namespace]: projectModule,
    [modalConstants.namespace]: modalModule,
    [requestOfferConstants.namespace]: requestOfferModule,
    [offerConstants.namespace]: offerModule,
    [spaceConstants.namespace]: spaceModule,
}

export {
    baseConstants,
    projectConstants,
    modalConstants,
    requestOfferConstants,
    offerConstants,
    spaceConstants,
}