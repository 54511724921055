<template>
  <div class="atom-radio-box" :data-box="box">
    <input
      :id="id"
      type="radio"
      :name="name"
      @change="onRadioClick"
      :value="value"
      :data-text="dataText"
      :checked="isChecked"
    />
    <label :for="id" v-html="label"></label>
  </div>
</template>

<script>
export default {
  name: "AtomRadio",
  components: {},
  props: {
    id: {
      required: false,
      type: String,
      default: "",
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    dataText: {
      required: false,
      type: String,
      default: "GLA",
    },
    name: {
      required: false,
      type: String,
      default: "checkbox",
    },
    box: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: [String, Number],
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onRadioClick(event) {
      this.$emit("get-value", event.target.value);
      this.$emit("get-data-text", event.target.dataset.text);

      const radioButton = this.$el;
      this.$el.parentNode.querySelectorAll(".hide-select").forEach((input) => {
        if (radioButton.getAttribute("data-box") === "no-visit") {
          input.classList.remove("active");
        }
        if (
          input.getAttribute("data-tab") ===
            `select-${radioButton.getAttribute("data-box")}` &&
          !input.classList.contains("active")
        ) {
          input.classList.add("active");
        } else {
          input.classList.remove("active");
        }
      });
    },
  },
};
</script>

<style lang="scss">
.atom-radio-box {
  position: relative;
  padding-left: 24px;
  display: inline-block;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    padding-left: 30px;
  }

  input[type="radio"] {
    display: none;
  }

  label {
    font-weight: $regular;
    font-size: 16px;
    line-height: 19px;
    color: #0a0a0a;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
    b {
      font-weight: $bold;
      font-size: 16px;
      line-height: 19px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &:after {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border: 1.5px solid $black;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        width: 14px;
        height: 14px;
        border-radius: 14px;
      }
    }

    &:before {
      opacity: 0;
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: #23cd9a;
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      }
    }
  }

  input[type="radio"] {
    &:checked + label {
      &:before {
        opacity: 1;
      }
    }
  }
}
</style>
