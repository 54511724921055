// Actions
const CHANGE_LOADING_STATE = "changeLoadingState";
const CHANGE_PROJECT_LOADING_STATE = "changeProjectLoadingState";
const CHANGE_ACTIVE_PROJECT = "changeActiveProject";
const LOAD_LANDLORD_DETAILS = "loadLandlordDetails";
const LOAD_PROJECT_AVAILABILITY = "loadProjectAvailability";
const CHANGE_FILTER_STATE = "changeFilterState";
const DOWNLOAD_FILE = "downloadFile";
const UPDATE_SURFACE_MULTIPLIER = "updateSurfaceMultiplier";
const UPDATE_SURFACE_NAME = "updateSurfaceName";

// Mutations
const SET_LANDLORD_DETAILS = "setLandlordDetails";
const SET_LOADING_STATE = "setLoadingState";
const SET_PROJECT_LOADING_STATE = "setProjectLoadingState";
const SET_PROJECT_AVAILABILITY = "setProjectAvailability";
const SET_ACTIVE_PROJECT = "setActiveProject";
const SET_FILTER_STATE = "setFilterState";
const SET_SURFACE_MULTIPLIER = "setSurfaceMultiplier";
const SET_SURFACE_NAME = "setSurfaceName";

// Namespace
const NAMESPACE = "project";

export const withNamespace = (string: string) => {
  return `${NAMESPACE}/${string}`;
};

export default {
  action: {
    CHANGE_LOADING_STATE,
    CHANGE_PROJECT_LOADING_STATE,
    CHANGE_ACTIVE_PROJECT,
    LOAD_LANDLORD_DETAILS,
    LOAD_PROJECT_AVAILABILITY,
    CHANGE_FILTER_STATE,
    DOWNLOAD_FILE,
    UPDATE_SURFACE_MULTIPLIER,
    UPDATE_SURFACE_NAME,
  },
  mutation: {
    SET_LANDLORD_DETAILS,
    SET_LOADING_STATE,
    SET_ACTIVE_PROJECT,
    SET_PROJECT_LOADING_STATE,
    SET_PROJECT_AVAILABILITY,
    SET_FILTER_STATE,
    SET_SURFACE_MULTIPLIER,
    SET_SURFACE_NAME,
  },
  namespace: NAMESPACE,
  withNamespace,
};
