import Vue from 'vue'
import 'es6-promise/auto'
import { createApp } from './main'
import ProgressBar from './components/atoms/ProgressBar.vue'
import axios from "axios";
import config from "@/config";

// global progress bar
const bar: any = Vue.prototype.$bar = new Vue(ProgressBar).$mount()
document.body.appendChild(bar.$el)

// a global mixin that calls `asyncData` when a route component's params change
Vue.mixin({
    beforeRouteUpdate (to, from, next) {
        // @ts-ignore
        const { asyncData } = this.$options
        if (asyncData) {
            asyncData({
                // @ts-ignore
                store: this.$store,
                route: to
            }).then(next).catch((error: any) => {
                console.log(error);
                next();
            })
        } else {
            next()
        }
    }
})

const getConfig = () => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(JSON.parse(xhr.responseText));
            } else {
                reject(xhr.responseText);
            }
        };
        xhr.open('GET', '/runtime');
        xhr.send();
    });
}

getConfig().then(async (cfg) => {
    const landlordDetails = await new Promise((resolve) => {
        if(config.API_URL){
            axios.get(`https://${config.API_URL}/landlord/details`)
                .then(res => {
                    resolve(res.data.result)
                })
                .catch(error => {
                    resolve({});
                })
        }
        else {
            axios.get(`http://localhost:3000/landlord/details`)
                .then(res => {
                    resolve(res.data.result)
                })
                .catch(error => {
                    resolve({});
                })
        }
    });
    const { app, router, store } = createApp({ url: '/', cfg: cfg, landlordDetails: landlordDetails })

    // prime the store with server-initialized state.
    // the state is determined during SSR and inlined in the page markup.
    // @ts-ignore
    if (window.__INITIAL_STATE__) {
        // @ts-ignore
        store.replaceState(window.__INITIAL_STATE__)
    }

    // wait until router has resolved all async before hooks
    // and async components...
    router.onReady(() => {
        // Add router hook for handling asyncData.
        // Doing it after initial route is resolved so that we don't double-fetch
        // the data that we already have. Using router.beforeResolve() so that all
        // async components are resolved.
        router.beforeResolve((to, from, next) => {
            const matched = router.getMatchedComponents(to)
            const prevMatched = router.getMatchedComponents(from)
            let diffed = false
            const activated = matched.filter((c, i) => {
                return diffed || (diffed = (prevMatched[i] !== c))
            })
            // @ts-ignore
            const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _)
            if (!asyncDataHooks.length) {
                return next()
            }

            bar.start()
            Promise.all(asyncDataHooks.map(hook => hook({ store, route: to })))
                .then(() => {
                    bar.finish()
                    next()
                })
                .catch(next)
        })

        // actually mount to DOM
        app.$mount('#app')
    })

}).catch(e => {
    console.log(e);
})