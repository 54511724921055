import {Route, RouteConfig} from 'vue-router';

const ProjectStaticPageRoutes = (cfg: any): Array<RouteConfig> => {
    const routerGroups: RouteConfig[] = [];
    const portfolioPageDisabled = cfg.general.portfolioPageDisabled;
    const basePath = portfolioPageDisabled ? '/:projectId' : '/project/:projectId';
    const projectStaticPages = cfg.general.projectStaticPages || [];

    if (portfolioPageDisabled) { // created this route to prevent unexpected url redirects from our clients (ex: one of our client is using a button with this url: "/projects/projectSlug" and we need to make sure that we can redirect our end-users to "/" in this case)
        const redirectComp = {
            path: '/project/:projectId',
            redirect: (to: any) => {
                let path: string = '/';
                if (cfg.general.redirectFromOtherDomain && cfg.general.redirectFromOtherDomain.from === to.path) {
                    path = cfg.general.redirectFromOtherDomain.to;
                }
                return { path }
            }
        }
        routerGroups.push(redirectComp);
    }

    projectStaticPages.forEach((page: any) => {
        const routerGroup = {
            path: `${basePath}${page.path}`,
            redirect: '',
            component: () => import(/* webpackChunkName: "[request]-template" */ (`../templates/${page.template || 'Base'}Template.vue`)),
            children: [
                {
                    path: "",
                    name: page.name,
                    component: () => import(/* webpackChunkName: "static-project-page-[request]" */ `../views/base/${page.type}Page.vue`),
                    meta: {
                        isProjectStaticPage: true,
                        page: {
                            meta: {
                                ...page.meta,
                                baseTitle: cfg.general.baseTitle
                            },
                            sections: page.sections
                        }
                    }
                }
            ]
        };
        routerGroups.push(routerGroup);
    });
    return routerGroups
}

export default ProjectStaticPageRoutes;