import * as localConf from './local'
import * as devConf from './dev'
import * as stagingConf from './staging'
import * as prodConf from './prod'

let exported = localConf;

if (process.env.VUE_APP_RUNTIME_ENV === 'development') {
    exported = devConf;
}

if (process.env.VUE_APP_RUNTIME_ENV === 'staging') {
    exported = stagingConf;
}

if (process.env.VUE_APP_RUNTIME_ENV === 'production') {
    exported = prodConf;
}

export {
    exported
};