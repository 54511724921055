<template>
  <div class="organism-page-loader" v-if="isVisible">
    <div class="border-element">
      <AtomCircleProgressLoader
        :size="296"
        :width="2"
        color="#000000"
        :progress="percentage"
      />
      <AtomLogo />
    </div>
    <div class="bright-logo">
      <a href="https://brightspaces.tech/" target="_blank">
        <img :src="resourceUrl('images/default-logo.svg')" alt="Bright" />
      </a>
    </div>
  </div>
</template>

<script>
import AtomLogo from "../../atoms/common/AtomLogo";
import AtomCircleProgressLoader from "@/components/atoms/common/AtomCircleProgressLoader";
export default {
  name: "OrganismPageLoader",
  components: { AtomCircleProgressLoader, AtomLogo },
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 10,
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.base.pageLoading;
    },
    documentReady() {
      return this.$store.state.base.documentIsReady;
    },
  },
  watch: {
    isVisible: function (newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.percentage = 0;
        this.startLoader();
      }
    },
  },
  mounted() {
    this.startLoader();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.closeInterval);
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    startLoader() {
      let ticks = 1;
      this.interval = setInterval(() => {
        const percentageChange = this.maxPercentage / this.seconds;
        if (
          this.percentage + percentageChange >= this.maxPercentage ||
          this.documentReady ||
          (document && document.readyState === "complete") ||
          ticks >= 2
        ) {
          console.log(
            "[PageLoader] Loading status: ",
            document
              ? document.readyState
              : this.documentReady
              ? "complete"
              : "loading"
          );
          clearInterval(this.interval);
          if (this.documentReady) this.percentage = 100;
          this.triggerLoaderHide();
          const base = this.$store.getters.constants.base;
          this.$store.dispatch(
            base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
            false
          );
          return;
        }
        ticks += 1;
        this.percentage += percentageChange;
      }, 1000);
    },
    triggerLoaderHide() {
      setTimeout(() => {
        const base = this.$store.getters.constants.base;
        this.$store.dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          false
        );
      }, 250);
    },
  },
};
</script>

<style lang="scss">
.organism-page-loader {
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .border-element {
    width: 300px;
    height: 300px;
    border-radius: 300px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .atom-circle-progress-loader {
      position: absolute;
      left: 2px;
      top: 2px;
    }
    .atom-logo {
      img {
        display: block;
        max-width: 70%;
        max-height: 100px;
        margin: 0 auto;
      }
    }
  }
  .bright-logo {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 70px;
    }
  }
}
</style>
