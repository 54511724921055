var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: { "button-atom": true },
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _vm.icon
        ? _c("AtomIcon", { attrs: { icon: _vm.icon, size: 14 } })
        : _vm._e(),
      _c("span", [_vm._v(_vm._s(_vm.label))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }