var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "molecule-request-offer": true, active: _vm.isShown },
      attrs: { id: "send-request" },
    },
    [
      _c("div", { staticClass: "popup-screen small-popup-screen" }, [
        _c(
          "span",
          { staticClass: "btn-close-popup", on: { click: _vm.closeModal } },
          [_c("AtomIcon", { attrs: { icon: "xmark", size: 16 } })],
          1
        ),
        _c("div", { staticClass: "content-center" }, [
          _c(
            "div",
            { staticClass: "content-center-wrapper" },
            [
              _c("div", { staticClass: "title-subtitle" }, [
                _c("h3", [_vm._v(_vm._s(_vm.message))]),
              ]),
              _c("AtomButton", {
                attrs: {
                  type: "button",
                  label: "Continue",
                  "on-click": _vm.closeModal,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }