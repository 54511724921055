import config from "../../../config";

class BaseApiModule {

    public readonly baseUrl: string;

    constructor() {
        this.baseUrl = config.API_URL ? `//${config.API_URL}` : "http://localhost:3000"
    }

}

export default BaseApiModule;