<template>
  <i :class="{'icon': true, [`icon-${icon}`]: true}" :style="{'font-size': `${size}px`, color}"></i>
</template>

<script>
export default {
  name: "AtomIcon",
  props: {
    icon: {
      required: true,
      type: String
    },
    size: {
      required: false,
      type: Number,
      default: 10
    },
    color: {
      required: false,
      type: String,
      default: "#000"
    }
  },
}
</script>

<style lang="scss">

</style>