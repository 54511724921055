import {SpaceState} from "./types";
import constants from "./constants";

const {
    SET_SPACE_DETAILS
} = constants.mutation;
export default {
    [SET_SPACE_DETAILS](state: SpaceState, data: any) {
        state.space = data;
        if (data.gallery) {
            state.gallery = data.gallery;
            state.photos = data.gallery.photos;
            state.videos = data.gallery.videos;
        }
    }
}