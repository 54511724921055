import { Route, RouteConfig } from "vue-router";

const BaseRoutes = (cfg: any): Array<RouteConfig> => {
  const routerGroups: RouteConfig[] = [];
  cfg.general.staticPages.forEach((page: any) => {
    const routerGroup = {
      path: page.path,
      redirect: "",
      component: () =>
        import(
          /* webpackChunkName: "[request]-template" */ `../templates/${
            page.template || "Base"
          }Template.vue`
        ),
      children: [
        {
          path: "",
          name: page.name,
          component: () =>
            import(
              /* webpackChunkName: "static-page-[request]" */ `../views/base/${page.type}Page.vue`
            ),
          meta: {
            page: {
              meta: {
                ...page.meta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: page.sections,
            },
          },
        },
      ],
    };
    routerGroups.push(routerGroup);
  });
  return routerGroups;
};

export default BaseRoutes;
