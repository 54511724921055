import {ActionContext} from "vuex";
import constants from "./constants";

const {
    LOAD_OFFER_DETAILS,
} = constants.action;
const {
    SET_OFFER_DETAILS,
} = constants.mutation;

export default {
    [LOAD_OFFER_DETAILS]({ commit, rootGetters }: ActionContext<any, any>, payload: any) {
        return new Promise((resolve, reject) => {
            rootGetters.requestHelper.GET(rootGetters.urlsHelper.landlord.getOffer(payload)).then((res: any) => {
                commit(SET_OFFER_DETAILS, res.data.result);
                resolve({
                    result: res,
                    error: null
                });
            }).catch((e: any) => {
                commit(SET_OFFER_DETAILS, false);
                reject({
                    result: null,
                    error: e,
                });
            });
        });
    },
}