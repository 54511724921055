const clientConfig = require(`../configs/${process.env.VUE_APP_BRIGHT_CLIENT}`);

const config = {
  ENV: process.env.NODE_ENV,
  VUE: process.env.VUE_ENV,
  RUNTIME_ENV: process.env.VUE_APP_RUNTIME_ENV,
  CLIENT: process.env.VUE_APP_BRIGHT_CLIENT,
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  ...clientConfig.exported.default,
};

export default config;
