import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import {OfferState} from "./types";

const state: OfferState = {
    offer: '',
    sections: [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}