import { RouteConfig } from "vue-router";

const ProjectRoutes = (cfg: any): Array<RouteConfig> => {
  const portfolioPageDisabled = cfg.general.portfolioPageDisabled;
  const { projectSpecificMeta, meta } = cfg.project;
  const displayMeta = { ...meta, projectSpecificMeta };
  return [
    {
      path: portfolioPageDisabled ? "/" : "/project",
      redirect: "",
      component: () =>
        import(
          /* webpackChunkName: "project-template" */ "../templates/ProjectTemplate.vue"
        ),
      children: [
        {
          path: "",
          name: portfolioPageDisabled ? "Home" : "Project",
          component: () =>
            import(
              /* webpackChunkName: "project-page" */ "../views/project/PageProject.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
            },
          },
        },
        {
          path: ":projectId",
          name: "SpecificProject",
          component: () =>
            import(
              /* webpackChunkName: "specific-project-page" */ "../views/project/PageProject.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
            },
          },
        },
        {
          path: ":projectId/space/:spaceId",
          name: "SpecificSpace",
          component: () =>
            import(
              /* webpackChunkName: "project-space" */ "../views/project/PageSpace.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
            },
          },
        },
      ],
    },
    {
      path: portfolioPageDisabled ? "/embed" : "/embed/project",
      redirect: "",
      component: () =>
        import(
          /* webpackChunkName: "project-embed-template" */ "../templates/ProjectEmbedTemplate.vue"
        ),
      children: [
        {
          path: ":projectId",
          name: "SpecificProjectEmbed",
          component: () =>
            import(
              /* webpackChunkName: "specific-project-embed-page" */ "../views/project/PageProjectEmbed.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
              isEmbedReady: true,
              isEmbedV2: false,
            },
          },
        },
        {
          path: ":projectId/space/:spaceId",
          name: "SpecificSpaceEmbed",
          component: () =>
            import(
              /* webpackChunkName: "project-space" */ "../views/project/PageSpaceEmbed.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
              isEmbedReady: true,
              isEmbedV2: false,
            },
          },
        },
      ],
    },
    {
      path: portfolioPageDisabled ? "/embed/v2" : "/embed/v2/project",
      redirect: "",
      component: () =>
        import(
          /* webpackChunkName: "project-embed-template" */ "../templates/ProjectEmbedTemplateV2.vue"
        ),
      children: [
        {
          path: ":projectId",
          name: "SpecificProjectEmbedV2",
          component: () =>
            import(
              /* webpackChunkName: "specific-project-embed-page" */ "../views/project/PageProjectEmbedV2.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
              isEmbedReady: false,
              isEmbedV2: true,
            },
          },
        },
        {
          path: ":projectId/space/:spaceId",
          name: "SpecificSpaceEmbedV2",
          component: () =>
            import(
              /* webpackChunkName: "project-space" */ "../views/project/PageSpaceEmbed.vue"
            ),
          meta: {
            page: {
              meta: {
                ...displayMeta,
                baseTitle: cfg.general.baseTitle,
              },
              sections: cfg.project.sections,
              isEmbedReady: false,
              isEmbedV2: true,
            },
          },
        },
      ],
    },
  ];
};

export default ProjectRoutes;
