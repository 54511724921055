var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "atom-circle-progress-loader",
      attrs: { width: _vm.size, height: _vm.size },
    },
    [
      _c("circle", {
        ref: "circle",
        staticClass: "progress-ring-circle",
        class: { mirrored: _vm.mirror },
        attrs: {
          stroke: _vm.color,
          "stroke-width": _vm.width,
          fill: "transparent",
          r: _vm.size / 2 - _vm.width * 2,
          cx: _vm.size / 2,
          cy: _vm.size / 2,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }