const LazyLoadDirective = {
    inserted: (el, binding) => {
        if (typeof window === 'undefined') return;

        let hasCustomHandler = false;
        let customHandler = () => {};
        if (typeof binding.value.handle === 'function') {
            hasCustomHandler = true;
            customHandler = binding.value.handle
        }

        function loadImage() {
            const imageElement = Array.from(el.children).find(
                el => el.nodeName === "IMG"
            );
            if (imageElement) {
                imageElement.addEventListener("load", () => {
                    setTimeout(() => el.classList.add("loaded"), 100);
                });
                imageElement.addEventListener("error", () => console.log("error"));
                imageElement.src = imageElement.dataset.url;
            }
        }

        function handleIntersect(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    hasCustomHandler ? customHandler() : loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options: IntersectionObserverInit = {
                root: null,
                threshold: "0"
            } as IntersectionObserverInit;
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }
        if (window["IntersectionObserver"]) {
            createObserver();
        } else {
            hasCustomHandler ? customHandler() : loadImage();
        }
    }
};

export {
    LazyLoadDirective
}