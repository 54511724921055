// Actions
const CHANGE_MODAL_STATE = "changeModalState";
const CLOSE_MODAL = "closeModal";

// Mutations
const SET_MODAL_STATE = "setModalState";

// Namespace
const NAMESPACE = "modal"

export const withNamespace = (string: string) => {
    return `${NAMESPACE}/${string}`;
}

export default {
    action: {
        CHANGE_MODAL_STATE,
        CLOSE_MODAL
    },
    mutation: {
        SET_MODAL_STATE
    },
    namespace: NAMESPACE,
    withNamespace
}