<template>
  <div class="organism-request-offer">
    <MoleculeRequestOffer />
    <MoleculeSendRequest ref="successMessage" />
  </div>
</template>

<script>
import MoleculeRequestOffer from "../../molecules/project/MoleculeRequestOffer";
import MoleculeSendRequest from "../../molecules/project/MoleculeSendRequest";
export default {
  name: "OrganismRequestOfferModal",
  components: {MoleculeSendRequest, MoleculeRequestOffer}
}
</script>

<style lang="scss" scoped>
  .organism-request-offer {

  }
</style>