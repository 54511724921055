import { RouteConfig } from "vue-router";

const StaticProjectsRoutes = (cfg: any): Array<RouteConfig> => {
  return [
    {
      path: "/:projectSlug",
      component: () =>
        import(
          /* webpackChunkName: "landing-template" */ "../templates/StaticTemplate.vue"
        ),
      children: [
        {
          path: "",
          name: "StaticPageOfProject",
          component: () =>
            import(
              /* webpackChunkName: "specific-project-embed-page" */ "../views/base/BrochurePage.vue"
            ),
          meta: {
            static: true,
          },
        },
      ],
    },
  ];
};
export default StaticProjectsRoutes;
