import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { ProjectState } from "./types";

const state: ProjectState = {
  isLoading: true,
  activeProject: "",
  projects: [],
  buildings: [],
  project: {
    isLoading: true,
    buildings: [],
    floors: [],
    spaces: [],
  },
  filters: {
    building: "",
    floor: "",
    surface: "",
  },
  surfaceMultiplier: 1,
  surfaceName: "GLA",
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
