import config from "./config";
import Vue from "vue";
import VueMeta from "vue-meta";
import { VTooltip, VClosePopover, VPopover } from "v-tooltip";
import App from "./App.vue";
import { WebApi, WebRequest } from "./helpers/api";
import { createRouter } from "./router";
import { createStore } from "./store";
import { sync } from "vuex-router-sync";
import { baseConstants } from "@/store/modules";
import { DirectiveBinding } from "vue/types/options";
import { LazyLoadDirective } from "./directives";

console.info("Running config", { CONFIG: config });

Vue.config.productionTip = false;

Vue.directive("lazyload", LazyLoadDirective);
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.directive("scroll", {
  inserted: function (el: HTMLElement, binding: DirectiveBinding) {
    const f = function (evt: any) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
Vue.component("v-popover", VPopover);
Vue.use({
  install: (app) => {
    app.prototype.$urls = WebApi;
    app.prototype.$request = WebRequest;
  },
});

Vue.prototype.$apiUrl = WebApi;
Vue.prototype.$request = WebRequest;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

export const createApp = function (context: any) {
  const router = createRouter(context.cfg);
  const store = createStore(router);
  sync(store, router);

  const currentRoute = router.resolve(context.url).route;

  store.state.base.meta = {
    ...context.cfg,
  };
  store.state.project.projects = context.landlordDetails.projects || [];
  if (
    context.landlordDetails &&
    context.landlordDetails.projects &&
    context.landlordDetails.projects.length &&
    !currentRoute.params.projectId
  ) {
    if (currentRoute.params.projectSlug) {
      store.state.project.activeProject = currentRoute.params.projectSlug;
    } else {
      store.state.project.activeProject =
        context.landlordDetails.projects[0].slug;
    }
  }
  if (currentRoute.params.projectId) {
    store.state.project.activeProject = currentRoute.params.projectId;
  }

  const app = new Vue({
    data: {
      url: context.url,
    },
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
};
