import { ActionContext } from "vuex";
import constants from "./constants";

const {
  LOAD_LANDLORD_DETAILS,
  LOAD_PROJECT_AVAILABILITY,
  CHANGE_LOADING_STATE,
  CHANGE_ACTIVE_PROJECT,
  CHANGE_FILTER_STATE,
  DOWNLOAD_FILE,
  UPDATE_SURFACE_MULTIPLIER,
  UPDATE_SURFACE_NAME,
} = constants.action;
const {
  SET_LANDLORD_DETAILS,
  SET_PROJECT_AVAILABILITY,
  SET_LOADING_STATE,
  SET_PROJECT_LOADING_STATE,
  SET_ACTIVE_PROJECT,
  SET_FILTER_STATE,
  SET_SURFACE_MULTIPLIER,
  SET_SURFACE_NAME,
} = constants.mutation;

export default {
  [LOAD_LANDLORD_DETAILS](
    { commit, dispatch, rootGetters, rootState }: ActionContext<any, any>,
    payload: any
  ) {
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    commit(SET_LOADING_STATE, true);
    changePageLoading(true);
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.details())
        .then((res: any) => {
          commit(SET_LANDLORD_DETAILS, res.data.result);
          commit(SET_LOADING_STATE, false);
          resolve({
            result: res,
            error: null,
          });
          if (res.data.result.projects.length) {
            const currentRoute = rootGetters.router.app.$route;
            if (
              ([
                "SpecificProject",
                "SpecificSpace",
                "SpecificProjectEmbed",
                "SpecificSpaceEmbed",
                "SpecificProjectEmbedV2",
                "SpecificSpaceEmbedV2",
              ].includes(currentRoute.name) ||
                currentRoute.meta.isProjectStaticPage) &&
              currentRoute.params.projectId
            ) {
              const foundProject = res.data.result.projects.find(
                (item: any) => item.slug === currentRoute.params.projectId
              );
              if (foundProject) {
                commit(SET_ACTIVE_PROJECT, foundProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, foundProject.id);
              } else {
                rootGetters.router.push({ name: "NotFound", params: {} });
              }
            } else {
              // we are checking for a project slug to load the group version
              let staticProjectOrDefault = { slug: "static-project", id: -1 };
              if (payload?.loadProjectBasedOnRouteSlug) {
                const routePieces = currentRoute.path.split("/");
                if (routePieces.length > 1) {
                  const projectSlug = routePieces[1];
                  staticProjectOrDefault = { slug: projectSlug, id: -1 };
                }
                commit(SET_ACTIVE_PROJECT, staticProjectOrDefault.slug);
              } else {
                const defaultProject = { ...res.data.result.projects[0] };
                commit(SET_ACTIVE_PROJECT, defaultProject.slug);
                dispatch(LOAD_PROJECT_AVAILABILITY, defaultProject.id);
              }
            }
          } else {
            changePageLoading(false);
          }
        })
        .catch((e: any) => {
          commit(SET_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  async [LOAD_PROJECT_AVAILABILITY](
    {
      commit,
      dispatch,
      state,
      rootGetters,
      rootState,
    }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_PROJECT_LOADING_STATE, true);
    const base = rootGetters.constants.base;
    const baseState = rootState.base;
    const changePageLoading = (value: boolean) => {
      if (baseState.documentIsReady) {
        dispatch(
          base.withNamespace(base.action.CHANGE_PAGE_LOADING_STATE),
          value,
          { root: true }
        );
      }
    };
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(payload))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          changePageLoading(false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [CHANGE_LOADING_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: boolean
  ) {
    commit(SET_LOADING_STATE, payload);
  },
  [CHANGE_ACTIVE_PROJECT](
    { commit, dispatch, state, rootGetters }: ActionContext<any, any>,
    payload:
      | string
      | { projectSlug: string; loadProjectBasedOnRouteSlug: boolean }
  ) {
    if (typeof payload !== "string") {
      commit(SET_ACTIVE_PROJECT, payload.projectSlug);
    } else {
      commit(SET_ACTIVE_PROJECT, payload);
    }
    commit(SET_PROJECT_LOADING_STATE, true);
    // we are checking if we are on a brochure route where we don't have to execute the availability call, because we are not using the availability for grouped projects
    if (typeof payload !== "string" && payload.loadProjectBasedOnRouteSlug) {
      commit(SET_PROJECT_LOADING_STATE, false);
      return;
    }

    const projectData = state.projects.find(
      (project: any) => project.slug === payload
    );
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .GET(rootGetters.urlsHelper.landlord.availability(projectData.id))
        .then((res: any) => {
          commit(SET_PROJECT_AVAILABILITY, res.data.result);
          commit(SET_PROJECT_LOADING_STATE, false);
          const requestOffer = rootGetters.constants.requestOffer;
          dispatch(
            requestOffer.withNamespace(requestOffer.action.CLEAR_STATE),
            null,
            { root: true }
          );
          resolve({
            result: res,
            error: null,
          });
        })
        .catch((e: any) => {
          commit(SET_PROJECT_LOADING_STATE, false);
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [CHANGE_FILTER_STATE](
    { commit, state }: ActionContext<any, any>,
    payload: any
  ) {
    commit(SET_FILTER_STATE, payload);
  },
  [DOWNLOAD_FILE](
    { commit, state, rootState, rootGetters }: ActionContext<any, any>,
    payload: any
  ) {
    return new Promise((resolve, reject) => {
      rootGetters.requestHelper
        .POST(rootGetters.urlsHelper.landlord.downloadFile(), payload)
        .then((res: any) => {
          resolve({
            result: res.data.result,
            error: null,
          });
        })
        .catch((e: any) => {
          reject({
            result: null,
            error: e,
          });
        });
    });
  },
  [UPDATE_SURFACE_MULTIPLIER](
    { commit, state }: ActionContext<any, any>,
    { surfaceMultiplier }: { surfaceMultiplier: number }
  ) {
    commit(SET_SURFACE_MULTIPLIER, { surfaceMultiplier });
  },
  [UPDATE_SURFACE_NAME](
    { commit, state }: ActionContext<any, any>,
    { surfaceName }: { surfaceName: string }
  ) {
    commit(SET_SURFACE_NAME, { surfaceName });
  },
};
