import { render, staticRenderFns } from "./OrganismModals.vue?vue&type=template&id=21201c14&scoped=true&"
import script from "./OrganismModals.vue?vue&type=script&lang=js&"
export * from "./OrganismModals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21201c14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21201c14')) {
      api.createRecord('21201c14', component.options)
    } else {
      api.reload('21201c14', component.options)
    }
    module.hot.accept("./OrganismModals.vue?vue&type=template&id=21201c14&scoped=true&", function () {
      api.rerender('21201c14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/OrganismModals.vue"
export default component.exports