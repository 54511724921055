var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-check-box", on: { click: _vm.handleClick } },
    [
      _c("input", {
        ref: "checkbox",
        attrs: { id: _vm.id, type: "checkbox", name: _vm.name },
        domProps: { value: _vm.checked },
      }),
      _c("label", { domProps: { innerHTML: _vm._s(_vm.label) } }),
      _c(
        "span",
        { class: { "check-box": true, checked: _vm.checked } },
        [
          _vm.checked
            ? _c("AtomIcon", {
                attrs: { icon: "square-check", size: _vm.size },
              })
            : _c("AtomIcon", { attrs: { icon: "square", size: _vm.size } }),
        ],
        1
      ),
      _vm.error
        ? _c("span", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.error)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }