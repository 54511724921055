import {RouteConfig} from 'vue-router';

const OfferRoutes = (cfg: any): Array<RouteConfig> => {
    const general = cfg.general;
    const config = cfg.offer;
    return [{
        path: '/offer',
        redirect: '',
        component: () => import(/* webpackChunkName: "offer-template" */ '../templates/OfferTemplate.vue'),
        children: [
            {
                path: ':offerId',
                name: 'OfferView',
                component: () => import(/* webpackChunkName: "offer-page" */ '../views/offer/PageOffer.vue'),
                meta: {
                    page: {
                        meta: {
                            ...config.meta,
                            baseTitle: general.baseTitle,
                        },
                        sections: config.general.sections
                    }
                }
            }
        ]
    }]
};

export default OfferRoutes;