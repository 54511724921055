<template>
  <div class="atom-logo">
    <a href="javascript:void(0)">
      <img :src="resourceUrl" />
    </a>
  </div>
</template>

<script>
export default {
  name: "AtomLogo",
  props: {
    white: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    resourceUrl() {
      let base = `${this.$store.getters.cdnBase}/`;
      if (this.white) {
        if (this.$store.getters.logoImageWhite.includes('://')) base = '';
        return `${base}${this.$store.getters.logoImageWhite}`;
      }
      if (this.$store.getters.logoImage.includes('://')) base = '';
      return `${base}${this.$store.getters.logoImage}`;
    }
  },
}
</script>

<style lang="scss">
  .atom-logo {
    display: inline-block;
    img {
      max-height: 7.441rem;
      @media only screen and (max-width: 767px) {
        max-height: 52px;
      }
    }
  }
</style>