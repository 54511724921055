import {ModalState} from "./types";
import constants from "./constants";

const {
    SET_MODAL_STATE
} = constants.mutation;

export default {
    [SET_MODAL_STATE](state: ModalState, data: any) {
        state.showModal = data.showModal;
        state.modalType = data.modalType;
    },
}