<template>
  <svg
      class="atom-circle-progress-loader"
      :width="size"
      :height="size">
    <circle ref="circle"
        class="progress-ring-circle"
        :class="{mirrored: mirror}"
        :stroke="color"
        :stroke-width="width"
        fill="transparent"
        :r="(size / 2) - (width * 2)"
        :cx="(size / 2)"
        :cy="(size / 2)"
    />
  </svg>
</template>

<script>
export default {
  name: "AtomCircleProgressLoader",
  props: {
    color: {
      required: true,
      type: String,
      default: '#FFF',
    },
    size: {
      required: true,
      type: Number,
      default: 60,
    },
    width: {
      required: true,
      type: Number,
      default: 4,
    },
    progress: {
      required: true,
      type: Number,
      default: 0,
    },
    mirror: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.updatePercentage();
  },
  watch: {
    progress() {
      this.updatePercentage();
    }
  },
  methods: {
    updatePercentage() {
      const circle = this.$refs.circle;
      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      circle.style.stroke = `rgba(0,0,0,0.5)`;
      let offset = circumference - this.progress / 100 * circumference;
      if (this.mirror) {
        offset = circumference + this.progress / 100 * circumference;
      }
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = offset;
    },
  },
}
</script>

<style lang="scss" scoped>
.atom-circle-progress-loader {
  .progress-ring-circle {
    // axis compensation
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: #FFF;
  }
}
</style>