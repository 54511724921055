import {BaseApiModule, AuthApiModule, LandlordApiModule} from "./modules";

class WebApi {

    public readonly base: BaseApiModule;
    public readonly auth: AuthApiModule;
    public readonly landlord: LandlordApiModule;

    constructor() {
        this.base = new BaseApiModule();
        this.auth = new AuthApiModule();
        this.landlord = new LandlordApiModule();
    }


}

const webApiInstance = new WebApi();

export default webApiInstance;